import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// Images
import HAGroup0 from "../../Assets/images/img/home-about-e0.svg";
import HAG from "../../Assets/images/img/about-home.png"

const AboutEaglegroup = () => {
  
  return (
    <>
      <div className="section-pt-80 ">
        <div className="about-container px-lg-5 px-3 py-4">
          <div className="container about-eagle-container">
            <div className="row align-items-center mb-4">
              <motion.div className="section-title col-12"
                initial={{
                  opacity: 0,
                  y: 100
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, 
                  transition: {
                    duration: 1, 
                  },
                }}
                viewport={{ once: false }}
              >
                <h6>About Eaglegroup</h6>
                <h2 className="title text-dark">Leading Indian Textile</h2>
                <span className="title fw-normal">Conglomerate</span>
              </motion.div>
            </div>
            <motion.div className="row align-items-lg-center"
              initial={{
                opacity: 0,
                y: 100
              }}
              whileInView={{
                opacity: 1,
                y: 0, 
                transition: {
                  duration: 1, 
                },
              }}
              viewport={{ once: false }}
            >
              <div className="col-lg-3 col-md-6 text-center text-md-start mb-4 mb-lg-0 d-none d-lg-block">
                <img
                  src={HAGroup0}
                  alt="curve line"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <p className="about-para">
                  At Eagle Group, a leading Indian textile conglomerate, we
                  believe in adding enormous value to the textile supply chain in
                  the form of topmost quality at the most competitive prices at
                  the convenience of the customer.
                </p>
                <p className="about-para">
                  This is not just a corporate slogan but also a way of life. We
                  are driven by a performance ethic pegged on value creation for
                  our multiple stakeholders.
                </p>
                <Link to="profile" className="btn transparent-btn">
                  <div className="btn_m">
                    <div className="btn_c">
                      <div className="btn_t1">
                        <span className="pe-2">more about</span>
                        <i className="fa fa-arrow-right rotate-icon fs-5"></i>
                      </div>
                      <div className="btn_t2">
                        <span className="pe-2">more about</span>
                        <i className="fa fa-arrow-right rotate-icon fs-5"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-5 col-md-6 text-center text-lg-end curve-img mx-auto">
                <img
                  src={HAG}
                  alt="fibers"
                  className="img-fluid"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutEaglegroup;
