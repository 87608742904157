import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useNavbarHook = () => {
    const [openAccordion, setOpenAccordion] = useState("");
    const [openMenu, setOpenMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const location = useLocation();


    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? "" : id);
    };

    const handleToggle = () => {
        setOpenMenu(!openMenu);
    };

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setOpenMenu(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return {
        openAccordion,
        toggleAccordion,
        openMenu,
        handleToggle,
        handleCloseMenu,
        scrolled,
        location,
    };
};

export default useNavbarHook;
