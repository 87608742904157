import React from "react";
import { motion } from "framer-motion";
import Team from "../../Assets/images/img/team.png";
import { useLocation } from "react-router-dom";
import { ImageLink } from "../../constants";
import useManagementHook from "./Management.hook";

const Management = ({ navData }) => {

  const { teamMembers } = useManagementHook()

  console.log("Team Members", teamMembers);

  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const des = navData?.filter((item) => item.menuUrl === currentPath).map((item) => item.description)
  const description = des[0]

  return (
    <>
      <div className="mt-4 mb-4">
        <div className="container management-section">
          <div className="row pb-60">
            <div className="col-md-12">
              <motion.img
                src={Team}
                alt="team"
                className="img-fluid aos-init"
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: false }}
              />
            </div>
          </div>
          <motion.div
            className="row"
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: false }}
          >
            <div className="col-lg-12">
              <p className="management-dis">
                Eagle Group is a family-run, values-based business with three
                generations actively involved in management. Our leadership is
                innovative, professional, and dedicated to ensuring that every
                team member is accountable and growth-focused. While we uphold
                the values and traditions of a family business, we operate with
                the highest level of professionalism, driving continuous
                innovation and success
              </p>
              <p className="management-dis">
                Our leadership team is dedicated to fostering an innovative
                culture while upholding the values of our family business. Key
                members include:
              </p>
            </div>
            {/* <div className="col-lg-6">
              <p className="management-dis">
                The market leadership carved by the company is mainly due to the
                young and dynamic workforce, led under the cohesive direction of
                a dedicated management team - taking the company to greater
                heights.
              </p>
              <p className="management-dis">
                The team is always on the lookout for better opportunities and
                has capabilities to exploit them to the fullest.
              </p>
            </div> */}
          </motion.div>
        </div>

        <div className="container management-section">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h2 className="title text-dark">The TEAM includes:</h2>
              </div>
            </div>
          </div>
          <div className="row management-team-sec">
            {teamMembers?.length > 0 &&
              teamMembers
                ?.filter((member) => member?.isActive)
                .map((member, index) => (
                  <motion.div
                    className="col-sm-6 col-md-4 col-lg-3 mb-4"
                    key={member._id}
                    initial={{
                      opacity: 0,
                      x: 25,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                        delay: index * 0.2,
                      },
                    }}
                    viewport={{ once: false }}
                  >
                    <div className="management-team text-center">
                      <img
                        src={ImageLink(member?.image)}
                        alt={member?.name}
                        className="img-fluid"
                      />
                      <div className="mgmt-details">
                        <p className="management-job m-0">{member?.profession}</p>
                      </div>
                    </div>
                    <p className="text-center mb-1 mt-2">{member?.job}</p>
                    <h5 className="team-name text-center pt-0">{member?.name}</h5>
                  </motion.div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Management;
