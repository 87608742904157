import { BASE_URL } from "../../services/axios.url";

const CompanyHook = async (company) => {
    try {
        const response = await fetch(`${BASE_URL}company/getCompanyBySlug/${company}`);
        if (!response.ok) {
            throw new Error('Failed to fetch the data');
        }
        const json = await response.json();
        return json.data;
    } catch (error) {
        console.error("Error fetching company data:", error);
        return null;
    }
};

export default CompanyHook;
