import React from "react";
import "odometer/themes/odometer-theme-default.css";
import OurProducts from "./OurProducts";
import WeBelieve from "./WeBelieve";
import AboutEaglegroup from "./AboutEaglegroup";
import Carousel from "./Carousel";
import OurCompanies from "./OurCompanies";
import { motion } from "framer-motion";
import HAbout from "../../Assets/images/img/home-about.svg";
import S1 from "../../Assets/images/banner/sumeet-banner.jpg";
import { ImageLink } from "../../constants";
import useHomeHook from "./Home.hook";
import { Link } from "react-router-dom";

const Homepage = (
  {
    products,
    companyData,
    isMobile
  }
) => {

  const {
    counters,
    odometerRefsDesktop,
    odometerRefsMobile,
    countersSectionRef
  } = useHomeHook()

  return (
    <>
      <main>
        <section className="slider-area">
          <Carousel isMobile={isMobile} />
        </section>
        <section ref={countersSectionRef} className="section-pt-80">
          <div className="container">
            <motion.div
              initial={{
                opacity: 0,
                y: 100,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: false }}
            >
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="position-relative">
                    <img src={HAbout} alt="Business" />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 d-flex flex-column justify-content-around">
                  <div className="business-heading aos-init">
                    <h1 className="text-capitalize text-center title-about">
                      <span>
                        A Purpose-Led Marketplace
                        <br />
                        That is Revolutionizing
                      </span>
                      <br />
                      <span className="text-secondary">
                        The Textile Industry
                      </span>
                    </h1>
                  </div>

                  <div className="d-none d-lg-flex justify-content-between">
                    {counters?.length > 0 && counters?.map((counter, index) => (
                      <div className="col-md-4 mb-3" key={counter?._id}>
                        <div className="card-counter text-center">
                          <img
                            src={ImageLink(counter?.image)}
                            alt={counter?.title}
                          />
                          <h6 className=" ld my-2 text-capitalize">
                            {counter?.name}
                          </h6>
                          <h1 className=" ld mb-0">
                            <span
                              ref={(el) =>
                                (odometerRefsDesktop.current[index] = el)
                              }
                            >
                              0
                            </span>
                          </h1>
                          <p className="mb-0  ld counter-unit text-lowercase">
                            {counter?.title}
                          </p>
                          <p className=" ld text-capitalize">
                            {counter?.subTitle}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="row d-lg-none mt-4"
              initial={{
                opacity: 0,
                y: 100,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: false }}
            >
              {counters?.length > 0 && counters?.map((counter, index) => (
                <div className="col-md-4 mb-3" key={counter?._id}>
                  <div className="card-counter text-center">
                    <img
                      src={ImageLink(counter?.image)}
                      alt={counter?.alt}
                    />
                    <h6 className=" ld my-2">{counter?.name}</h6>
                    <h1 className=" ld mb-0">
                      <span
                        ref={(el) => (odometerRefsMobile.current[index] = el)}
                      >
                        0
                      </span>
                    </h1>
                    <p className="mb-0 counter-unit  ld">
                      {counter?.title}
                    </p>
                    <p className=" ld">{counter?.subTitle}</p>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
        </section>
        <section className="section-pt-80">
          <div className="container">
            <motion.div
              initial={{
                opacity: 0,
                y: 100,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: false }}
            >
              <div className="row">
                <div className="col-md-12">
                  <Link to="https://www.sumeetindustries.com/" target="_blank">
                    <img src={S1} alt="S1" />
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
        <OurProducts
          products={products}
        />
        <WeBelieve />
        <AboutEaglegroup />
        <OurCompanies companyData={companyData} />
      </main>
    </>
  );
};

export default Homepage;