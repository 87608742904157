import React from "react";
import { motion } from "framer-motion";
import Fancybox from "../../Components/FancyBox";
import { ImageLink } from './../../constants';

const Infrastructure = ({companyData}) => {
 
  return (
    <>
     
      <div className="mt-4 mb-4">
        <div className="container infra-section">
          {companyData?.data?.length > 0 &&
            companyData?.data?.map((data) => (
              <div key={data?._id}>
                <div className="col-xl-12">
                  <div className="section-title aos-init">
                    <h3
                      className="title text-dark"
                      style={{ fontSize: "26px" }}
                    >
                      {data?.name}
                    </h3>
                  </div>
                </div>
                <div className="row infra-embroidery mb-4 mt-4">
                  {data?.desktopImg?.length > 0 && data?.desktopImg?.map((img, index) => (
                    <motion.div
                      className="col-xl-4 col-lg-4 col-md-6 col-6 mb-4"
                      key={`${data._id}-img-${index}`}
                      initial={{
                        opacity: 0,
                        x: 25,
                      }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 1,
                          delay: index * 0.2,
                        },
                      }}
                      viewport={{ once: false }}
                    >
                      <Fancybox>
                        <a
                          data-fancybox={`gallery-${data._id}`}
                          href={ImageLink(img)}
                          className="fancybox-link"
                        >
                          <img
                            src={ImageLink(img)}
                            alt={`Company-Image ${index + 1}`}
                            className="w-100"
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                      </Fancybox>
                    </motion.div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Infrastructure;
