import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Fancybox from "../../Components/FancyBox";
import { ImageLink } from "../../constants";
import useProductHook from "./Product.hook";

const ProductDetails = ({ products, allBanners, isMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const productUrl = location.pathname.split("/")[2];
  const [currentUrl, setCurrentUrl] = useState(null);
  const {
    activeProductData,
    activeProduct,
    handleTabClick
  } = useProductHook(products);

  useEffect(() => {
    setCurrentUrl(productUrl);
  }, [productUrl, products]);

  useEffect(() => {
    if (currentUrl) {
      const selectedProduct = products.find(product => product.url === currentUrl);

      if (selectedProduct) {
        handleTabClick(selectedProduct?.url, selectedProduct._id);
      }
    }
  }, [currentUrl, products, handleTabClick]);

  const bannerData = allBanners?.find(banner => banner?.menu?.menuUrl === "products");

  return (
    <>
      <section className="slider-area">
        <div className="video-w justify-content-center text-center position-relative">
          <img
            src={isMobile ? ImageLink(bannerData?.mobileImg) : ImageLink(bannerData?.desktopImg)}
            alt={bannerData?.title}
            className="w-100 banner-brightness"
          />
          <div className="banner-content-overlay">
            <div className="" data-aos="fade-up" data-aos-duration="1500">
              <h2 className="title" dangerouslySetInnerHTML={{ __html: bannerData?.title }}></h2>
            </div>
            <div className="" data-aos="fade-up" data-aos-duration="1500">
              <p className="subtitle" dangerouslySetInnerHTML={{ __html: bannerData?.description }}></p>
            </div>
          </div>
        </div>
      </section>

      <div className="home-projects">
        <div className="container product-section">
          <div className="row pt-20 pb-40">
            <div className="col-md-4">
              {products?.map((product) => (
                <div
                  key={product?._id}
                  className={`tab-item ${activeProduct === product?._id ? "active-tab" : ""}`}
                  onClick={() => {
                    navigate(`/product/${product?.url}`);
                    setCurrentUrl(product?.url);
                  }}
                >
                  <p className="tab-title mb-0 text-capitalize text-start border-bottom">
                    {product?.name}
                  </p>
                </div>
              ))}
            </div>
            <div className="col-md-8" name="product-section">
              <div>
                <h4 className="title text-dark fontPrdTitle mt-lg-0 mt-3">
                  {activeProductData?.name || "Product Name"}
                </h4>
              </div>

              <div className="mobile-title-sub">
                <h3 className="subtitle ps-0">
                  {activeProductData?.title || "Subtitle"}
                </h3>
                <p
                  className="text-justify"
                  dangerouslySetInnerHTML={{
                    __html: activeProductData?.description || "Description text.",
                  }}
                />
              </div>
              <div className="row">
                <Fancybox>
                  {activeProductData?.productImage?.map((image, index) => (
                    <div className="col-6 gy-5 product-items"
                      key={image?._id ? image._id : `${index}`}
                    >
                      <a
                        data-fancybox="gallery"
                        href={ImageLink(image)}
                        className="fancybox-link"
                      >
                        <img
                          src={ImageLink(image)}
                          alt={`${activeProductData?.name} Product-Image ${index + 1}`}
                          className="w-100"
                          style={{ cursor: "pointer" }}
                        />
                      </a>
                    </div>
                  ))}
                </Fancybox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;