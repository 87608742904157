import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { ImageLink } from "./../../constants";
import { getSliderData } from "../../services/axios.function";

const DemoCarousel = ({isMobile}) => {

  const [carouselData, setCarouselData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animate, setAnimate] = useState(false);

  const fetchSliderData = async () => {
    try {
      const result = await getSliderData();
      if (result.data !== carouselData) {
        setCarouselData(result.data);
      }
    } catch (error) {
      console.log("FETCH SLIDER DATA ERROR===>>>", error);
    }
  };

  useEffect(() => {
    fetchSliderData()
  }, [])

  const handleSlideChange = (index) => {
    setAnimate(false);
    setCurrentIndex(index);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setAnimate(true), 50);
    return () => clearTimeout(timeout);
  }, [currentIndex]);




  let count = 0;

  carouselData.forEach((item) => {
    if (item?.isActive) {
      count += 1;
    }
  });




  return (
    <Carousel
      showArrows={true}
      autoPlay={true}
      infiniteLoop={true}
      swipeable={true}
      showThumbs={false}
      emulateTouch={true}
      dynamicHeight={true}
      interval={3000}
      selectedItem={currentIndex}
      onChange={handleSlideChange}
      showIndicators={count > 1}
    >

      {carouselData?.length > 0 && carouselData?.filter((carousel) => carousel?.isActive)?.map((carousel, index) => (
        <div className="position-relative"
          key={carousel?._id}
          style={{ cursor: "move", userSelect: "none", WebkitUserDrag: "none" }}>
          <img
            src={isMobile ? ImageLink(carousel?.mobileImg) : ImageLink(carousel?.desktopImg)}
            alt={carousel?.title}
            className="w-100 h-75"
          />
          {currentIndex === index && (
            <div className="video-banner-content-center position-absolute">
              <div
                className={`${animate ? "animated fadeInUp animate-duration-4s" : ""
                  }`}
              >
                <h1
                  dangerouslySetInnerHTML={{ __html: carousel?.title }}
                  className="text-capitalize"
                  style={{ cursor: "text", userSelect: "text" }}
                ></h1>
              </div>
            </div>
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default DemoCarousel;