import React from "react";
import { ImageLink } from "../constants";
import useAppHook from "../App.hook";

const Banner = ({ bannerData }) => {  
    
   const {isMobile} = useAppHook()

    if (!bannerData) return null;

    return (
        <section className="slider-area">
            <div className="video-w justify-content-center text-center position-relative">
                <img
                    src={isMobile ? ImageLink(bannerData?.mobileImg) : ImageLink(bannerData?.desktopImg)}
                    alt={bannerData?.title}
                    className="w-100 banner-brightness"
                />
                <div className="banner-content-overlay ">
                    <div className="" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="title" dangerouslySetInnerHTML={{ __html: bannerData?.title }}></h2>
                    </div>
                    <div className="" data-aos="fade-up" data-aos-duration="1500">
                        <p className="subtitle" dangerouslySetInnerHTML={{__html:bannerData?.description}}></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
