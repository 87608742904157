// import React from "react";
// import { useLocation } from "react-router-dom";
// import { motion } from "framer-motion";
// import useMilestoneHook from "./Milestone.hook";

// const Milestone = ({ navData }) => {
//   const {
//     milestones,
//     isMobile,
//     truncateDescription
//   } = useMilestoneHook();


//   const location = useLocation();
//   const currentPath = location.pathname.split("/")[1];


//   const des = navData?.filter((item) => item.menuUrl === currentPath).map((item) => item.description)
//   const description = des[0]

//   return (
//     <>
//       <div className="container milestone-section">
//         <div dangerouslySetInnerHTML={{
//           __html: description
//         }}>
//         </div>

//         <div className="row pt-40">
//           <div className="col-md-12">
//             <div className="timeline">
//               {milestones?.map((milestone, index) => (
//                 <motion.div
//                   key={milestone?._id}
//                   initial={{ opacity: 0, y: 100 }}
//                   whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
//                   viewport={{ once: false }}
//                 >
//                   <MilestoneItem
//                     key={index}
//                     milestone={milestone}
//                     isRightAligned={index % 2 === 0}
//                     isMobile={isMobile}
//                     truncateDescription={truncateDescription}
//                   />
//                 </motion.div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// const MilestoneItem = ({ milestone, isRightAligned, isMobile, truncateDescription }) => {

//   const {
//     isExpanded,
//     wordLimit,
//     toggleDescription
//   } = useMilestoneHook()

//   return (
//     <div className={`milestone ${isRightAligned ? "right" : "left"}`}>
//       <i className="icon">{milestone?.year}</i>
//       <div className="content">
//         <p>
//           {isMobile && !isExpanded
//             ? truncateDescription(milestone?.description, wordLimit)
//             : milestone?.description}
//           {isMobile && milestone?.description.split(" ")?.length > wordLimit && (
//             <span className="show-more" onClick={toggleDescription}>
//               {!isExpanded ? "...Show More" : " Show less"}
//             </span>
//           )}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Milestone;


import React from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import useMilestoneHook from "./Milestone.hook";
import Mile from "../../Assets/images/img/milestone.png";

const Milestone = ({ navData }) => {
  const {
    milestones,
    isMobile,
    truncateDescription,
    isExpanded,
    wordLimit,
    toggleDescription
  } = useMilestoneHook();

  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  const des = navData?.filter((item) => item.menuUrl === currentPath).map((item) => item.description)
  const description = des[0]

  return (
    <>
      <div className="container milestone-section">
        {/* <div dangerouslySetInnerHTML={{
          __html: description
        }}>
        </div> */}
        <motion.div
          className="row"
          initial={{
            opacity: 0,
            y: 100,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: false }}
        >
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <img src={Mile} alt="MIS" />
              <h2 className="title text-dark mx-4">Our Journey</h2>
            </div>
          </div>
          <div className="col-md-6">
            <p className="milestone-dis">
              A milestone represents a key achievement or turning point in a
              project or business journey. It signifies measurable progress and
              helps to break down larger goals into manageable steps. Milestones
              provide a sense of accomplishment, enabling teams to reflect on
              their success while staying focused on the path ahead. By reaching
              these crucial markers, businesses can ensure they are on track,
              celebrate their achievements, and plan for future growth.
            </p>
          </div>
        </motion.div>

        <div className="row pt-40">
          <div className="col-md-12">
            <div className="timeline">
              {milestones?.map((milestone, index) => (
                <motion.div
                  key={milestone?._id}
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
                  viewport={{ once: false }}
                >
                  <MilestoneItem
                    key={milestone?._id}
                    milestone={milestone}
                    isRightAligned={index % 2 === 0}
                    isMobile={isMobile}
                    truncateDescription={truncateDescription}
                    isExpanded={isExpanded}
                    wordLimit={wordLimit}
                    toggleDescription={toggleDescription}
                  />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MilestoneItem = ({
  milestone,
  isRightAligned,
  isMobile,
  truncateDescription,
  isExpanded,
  wordLimit,
  toggleDescription
}) => {
  return (
    <div className={`milestone ${isRightAligned ? "right" : "left"}`}>
      <i className="icon">{milestone?.year}</i>
      <div className="content">
        <p>
          {isMobile && !isExpanded
            ? truncateDescription(milestone?.description, wordLimit)
            : milestone?.description}
          {isMobile && milestone?.description.split(" ")?.length > wordLimit && (
            <span className="show-more" onClick={toggleDescription}>
              {!isExpanded ? "...Show More" : " Show less"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default Milestone;
