import { GET, POST } from "./axios.api";
import {
  BANNERURL,
  CATEGORYFILEURL,
  COMPANYURL,
  CONTACTURL,
  COUNTERURL,
  EXCELLENCEURL,
  MANAGEMENTURL,
  MILESTONEURL,
  NAVBARURL,
  PRODUCTURL,
  REPORTCATEGORYURL,
  SETTINGURL,
  SLIDERURL,
  SOCIALMEDIAURL,
} from "./axios.url";

export const getSliderData = async () => {
  const res = await GET(SLIDERURL);
  return res;
};

export const getCounterData = async () => {
  const res = await GET(COUNTERURL);
  return res;
};

export const getCompanyData = async () => {
  const res = await GET(COMPANYURL);
  return res;
};

export const getBannerData = async () => {
  const res = await GET(BANNERURL);
  return res;
};

export const getManagementData = async () => {
  const res = await GET(MANAGEMENTURL);
  return res;
};

export const getMilestoneData = async () => {
  const res = await GET(MILESTONEURL);
  return res;
};

export const getSocialMediaData = async () => {
  const res = await GET(SOCIALMEDIAURL);
  return res;
};

export const getSettingData = async () => {
  const res = await GET(SETTINGURL);
  return res;
};

export const getProductData = async () => {
  const res = await GET(PRODUCTURL);
  return res;
};

export const getMenuData = async () => {
  const res = await GET(NAVBARURL);
  return res;
};

export const getReportCategory = async () => {
  const res = await GET(REPORTCATEGORYURL);
  return res;
};

export const getCategoryFile = async () => {
  const res = await GET(CATEGORYFILEURL);
  return res;
};

export const postEnquiryData = async (payload) => {
    const res = await POST(CONTACTURL, payload)
    return res
}
export const getExcellenceData = async () => {
  const res = await GET(EXCELLENCEURL);
  return res;
};