import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Meta = ({ keywords, navData, settingData, products }) => {
  const location = useLocation();

  const defaultTitle =
    settingData?.data?.title ||
    "Eagle Group - Leading Specialty Yarn Producer in India";
  const defaultDescription =
    settingData?.data?.description ||
    `Eagle Group is a leading Indian textile conglomerate revolutionizing the textile industry. Explore the top-quality yarn and fabric products with enormous value and competitive prices.`;

  const currentPath = location?.pathname.replace(/^\/+/, "");

  const matchingNavItem = navData?.find((item) => {
    const mainPath = item?.menuUrl?.replace(/^\/+/, "");

    if (mainPath === currentPath) return true;

    if (item?.submenu) {
      for (const subItem of item.submenu) {
        const subPath =
          subItem?.menuType === "Company"
            ? `company/${subItem.menuUrl}`
            : subItem.menuUrl;

        if (subPath?.replace(/^\/+/, "") === currentPath) {
          item.title = subItem.title;
          item.description = subItem.description;
          return true;
        }
      }
    }
    return false;
  });

  const matchProductUrl = products?.find((item) => {
    const productPath = `/product/${item?.url?.replace(/^\/+/, "")}`;

    if (window.location.pathname.includes(productPath)) {
      return {
        title: item?.title,
        description: item?.description
      };
    }
  });

  const title = matchingNavItem?.title || matchProductUrl?.name || defaultTitle;
  const description = matchingNavItem?.metaDescription || matchProductUrl?.title || defaultDescription;

  return (
    <Helmet>
      <title>{`${title}`}</title>

      <meta
        name="description"
        content={description || `${title} - ${description}`}
      />

      <meta
        name="keywords"
        content={
          keywords || "yarn, specialty yarn, producer, textiles, industry"
        }
      />

      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default Meta;
