import axiosInstance from "../axiosInterceptor";

export async function GET(url) {
    try {
        const response = await axiosInstance.get(url);
        const data = response;
        return data;
    }
    catch (error) {
        if (error.response) {
            console.log("Error Data:", error?.response);
            console.log("Error Status:", error?.response?.status);
            console.log("Error Headers:", error?.response?.headers);
            return error?.response;
        } else if (error.request) {
            console.log("Request Error:", error?.request);
            return error?.request;
        } else {
            console.log("Error Message:", error?.message);
            return error?.message;
        }
    }
}


export async function POST(url, payload) {
    try {
        const response = await axiosInstance.post(url, payload);
        const data = response;
        return data;
    }
    catch (error) {
        if (error.response) {
            console.log("Error Data:", error?.response?.data);
            console.log("Error Status:", error?.response?.status);
            console.log("Error Headers:", error?.response?.headers);
            return error?.response;
        } else if (error.request) {
            console.log("Request Error:", error?.request);
            return error?.request;
        } else {
            console.log("Error Message:", error?.message);
            return error?.message;
        }
    }
}
