import { useState } from "react";

const useAboutHook = (aboutData) => {
  const [activeData, setActiveData] = useState(1);

  const activeAboutData = aboutData?.find(
    (aboutData) => aboutData.id === activeData
  );

  return {
    activeData, setActiveData,activeAboutData
  };
};

export default useAboutHook;
