import React, { useEffect } from "react";
import error from "../Assets/images/img/404.jpg";
import { Link, useNavigate } from "react-router-dom";

const ErrorPage = () => {



    const navigation = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigation('/');
        }, 1000);
        return () => clearTimeout(timeout);
    }, [navigation]);

    return (
        <>
            <section className="slider-area section-pt-80">
                <div className="text-center ">
                    <img src={error} alt="error" />
                </div>
                <div className="text-center my-3">
                    <Link to="/" className="btn transparent-btn">
                        <div className="btn_m">
                            <div className="btn_c">
                                <div className="btn_t1">
                                    <i className="fa fa-arrow-left  fs-5"></i>
                                    <span className="pe-2">Back To Home</span>
                                </div>
                                <div className="btn_t2">
                                    <i className="fa fa-arrow-left  fs-5"></i>
                                    <span className="pe-2">Back To Home</span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>

            </section >
        </>
    );
};

export default ErrorPage;
