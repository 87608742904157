import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ImageLink } from "../../constants"

const Product = ({ products }) => {

  return (
    <>
      <div className="mt-4 home-projects">
        <div className="container product-section">
          <div className="row">
            {products?.length > 0 && products?.map((product, index) => (
              <motion.div
                className="col-lg-3 col-md-4 col-6 product-items"
                key={product?._id}
                initial={{
                  opacity: 0,
                  x: 25,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                    delay: index * 0.2,
                  },
                }}
                viewport={{ once: false }}
              >
                <div className="project-item  position-relative">
                  <div className="project-thumb img-brightness">
                    <Link to={`/product/${product?.url}`} >
                      <img
                        src={ImageLink(product?.bannerImage)}
                        alt={product?.name}
                        className="w-100"
                      />
                    </Link>
                  </div>
                  <div className="project-content p-3">
                    <h6 className="title text-white letter-spacing-1 text-capitalize mb-2">
                      {product?.name}
                    </h6>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;


