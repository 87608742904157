import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import useFooterHook from "./Footer.hook";
import { ImageLink } from "../../constants";

const Footer = ({ settingData, products, navData }) => {
  const {
    socialMediaData,
    open,
    toggle,
    isMobile,
    firstColumnProducts,
    secondColumnProducts,
    emailArray,
  } = useFooterHook(products, settingData);

  return (
    <>
      <footer className="">
        <div className="footer-area footer-bg">
          <div className="container">
            <div className="footer-top">
              <div className="row mb-4">
                {isMobile ? (
                  <div className="col-12">
                    <Accordion flush open={open} toggle={toggle}>
                      <AccordionItem>
                        <AccordionHeader targetId="1">
                          <div className="d-flex justify-content-between w-100">
                            <span>Quick Links</span>
                            <i
                              className={`fas fa-chevron-down ms-1 ${
                                open === "1" ? "rotate-icon-2" : "reset-icon"
                              }`}
                            ></i>
                          </div>
                        </AccordionHeader>
                        <AccordionBody
                          className="accordion-body-style"
                          accordionId="1"
                        >
                          <ul className="list-wrap">
                            {navData?.length > 0 &&
                              navData
                                .filter((item) => item?.showInFooter)
                                .map((item, index) => (
                                  <li key={item?._id || index}>
                                    <Link
                                      to={item?.menuUrl}
                                      className="text-capitalize"
                                    >
                                      {item?.menuName}
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="2">
                          <div className="d-flex justify-content-between w-100">
                            <span>Our Products</span>
                            <i
                              className={`fas fa-chevron-down ms-1 ${
                                open === "2" ? "rotate-icon-2" : "reset-icon"
                              }`}
                            ></i>
                          </div>
                        </AccordionHeader>
                        <AccordionBody
                          className="accordion-body-style"
                          accordionId="2"
                        >
                          <ul className="list-wrap">
                            {products?.map((product) => (
                              <li key={product?._id}>
                                <Link
                                  to={`/product/${product?.url}`}
                                  className="text-capitalize"
                                >
                                  {product?.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader targetId="3">
                          <div className="d-flex justify-content-between w-100">
                            <span>Our Companies</span>
                            <i
                              className={`fas fa-chevron-down ms-1 ${
                                open === "3" ? "rotate-icon-2" : "reset-icon"
                              }`}
                            ></i>
                          </div>
                        </AccordionHeader>
                        <AccordionBody
                          className="accordion-body-style"
                          accordionId="3"
                        >
                          {navData &&
                            navData?.map((menu) => (
                              <React.Fragment key={menu?._id}>
                                {menu?.submenu &&
                                  menu.submenu?.length > 0 &&
                                  (menu?.menuUrl === "company" ? ( 
                                    <ul className="list-wrap">
                                      {menu?.submenu
                                        .filter((item) => item?.showInFooter)
                                        .map((submenu) => (
                                          <li key={submenu?._id}>
                                            <Link
                                              to={`${menu?.menuUrl}/${submenu?.menuUrl}`}
                                              className="text-capitalize"
                                            >
                                              {submenu?.menuName}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  ) : null)}
                              </React.Fragment>
                            ))}
                        </AccordionBody>
                      </AccordionItem>
                    </Accordion>
                  </div>
                ) : (
                  <>
                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-7">
                      <div className="footer-widget">
                        <h2 className="fw-title">Quick Link</h2>
                        <motion.div
                          className="footer-link"
                          initial={{
                            opacity: 0,
                          }}
                          whileInView={{
                            opacity: 1,
                            transition: {
                              duration: 2,
                            },
                          }}
                          viewport={{ once: false }}
                        >
                          <ul className="list-wrap">
                            {navData?.length > 0 &&
                              navData      
                                .filter((item) => item?.showInFooter) 
                                .map((item, index) => (
                                  <li key={item?._id || index}>
                                    <Link
                                      to={item?.menuUrl}
                                      className="text-capitalize"
                                    >
                                      {item?.menuName}
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        </motion.div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-8">
                      <div className="footer-widget">
                        <h2 className="fw-title">Our Products</h2>
                        <div className="row">
                          <div className="col-6">
                            <motion.div
                              className="footer-link"
                              initial={{
                                opacity: 0,
                              }}
                              whileInView={{
                                opacity: 1,
                                transition: {
                                  duration: 2,
                                },
                              }}
                              viewport={{ once: false }}
                            >
                              <ul className="list-wrap">
                                {firstColumnProducts?.map((product) => (
                                  <li key={product?._id}>
                                    <Link to={`/product/${product?.url}`}>
                                      {product?.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </motion.div>
                          </div>

                          <div className="col-6">
                            <motion.div
                              className="footer-link"
                              initial={{
                                opacity: 0,
                              }}
                              whileInView={{
                                opacity: 1,
                                transition: {
                                  duration: 2,
                                },
                              }}
                              viewport={{ once: false }}
                            >
                              <ul className="list-wrap column">
                                {secondColumnProducts?.map((product) => (
                                  <li key={product?._id}>
                                    <Link to={`/product/${product?.url}`}>
                                      {product?.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </motion.div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-7">
                      <div className="footer-widget">
                        <h2 className="fw-title">Our Companies</h2>
                        <motion.div
                          className="footer-link"
                          initial={{
                            opacity: 0,
                          }}
                          whileInView={{
                            opacity: 1,
                            transition: {
                              duration: 2,
                            },
                          }}
                          viewport={{ once: false }}
                        >
                          {navData &&
                            navData?.map((menu) => (
                              <React.Fragment key={menu?._id}>
                                {menu?.submenu &&
                                  menu?.submenu?.length > 0 &&
                                  (menu?.menuUrl === "company" ? ( 
                                    <ul className="list-wrap">
                                      {menu.submenu
                                        .filter((item) => item?.showInFooter)
                                        .map((submenu) => (
                                          <li key={submenu?._id}>
                                            <Link
                                              to={`${menu?.menuUrl}/${submenu?.menuUrl}`}
                                              className="text-capitalize"
                                            >
                                              {submenu?.menuName}
                                            </Link>
                                          </li>
                                        ))}
                                    </ul>
                                  ) : null)}
                              </React.Fragment>
                            ))}
                        </motion.div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row d-none d-lg-block d-md-block">
                <div className="footer-social">
                  <motion.ul
                    className="list-wrap justify-content-end"
                    initial={{
                      opacity: 0,
                    }}
                    whileInView={{
                      opacity: 1,
                      transition: {
                        duration: 2,
                      },
                    }}
                    viewport={{ once: false }}
                  >
                    {socialMediaData?.data?.length > 0 &&
                      socialMediaData?.data?.map((socialIcon) => (
                        <li key={socialIcon?._id}>
                          <Link href={socialIcon?.url}>
                            <i className={`${socialIcon?.icon} pe-1`}></i>
                            {socialIcon?.name}
                          </Link>
                        </li>
                      ))}
                  </motion.ul>
                </div>
              </div>
              <div className="row d-lg-none d-md-none">
                <div className="footer-social">
                  <ul className="list-wrap justify-content-center">
                    {socialMediaData?.data?.length > 0 &&
                      socialMediaData?.data?.map((socialIcon) => (
                        <li key={socialIcon?._id}>
                          <Link href={socialIcon?.url}>
                            <i
                              className={`${socialIcon?.icon} pe-1 s-icon`}
                            ></i>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row mb-4 wrap-border">
                <div className="col-lg-6 col-md-6 mb-3 mb-lg-0 mb-md-0  footer-contact">
                  <img
                    src={ImageLink(settingData?.data?.footerLogo)}
                    alt="logo"
                    className="footer-logo"
                  />
                </div>
                <div className="col-lg-3 col-md-6 footer-contact justify-content-start">
                  <div className="footer-ph-em">
                    <small className="m-0 text-white">Phone No.</small>
                    <p className="phone-email">
                      <Link
                        to={`tel:${settingData?.data?.phoneNumber}`}
                        className="text-white"
                      >
                        {settingData?.data?.phoneNumber}
                      </Link>
                      <br />
                      <Link
                        to={`tel:${settingData?.data?.mobileNumber}`}
                        className="text-white"
                      >
                        {settingData?.data?.mobileNumber}
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 d-lg-none"></div>
                <div className="col-lg-3 col-md-6 footer-contact  justify-content-start">
                  <div className="footer-ph-em">
                    <small className="m-0  text-white">Email Now</small>
                    <p className=" text-white phone-email">
                      {emailArray?.map((email, index) => (
                        <React.Fragment key={index}>
                          <Link to={`mailto:${email}`} className="text-white">
                            {email}
                          </Link>
                          {index === 0 && <br />}
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="copyright-text text-center">
                    <p>
                      © 2024 all rights reserved Eagle Group
                      <span className="copyright-space">|</span>Website
                      developed by :{" "}
                      <Link to="https://www.setblue.com/" target="_blank">
                        Setblue.com
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
