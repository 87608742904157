
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Contact = ({companyData}) => {

  return (
    <>
      <section className="slider-area">
        <div className="container mt-5">
          <div className="contact-form-container">
            <div className="row">
              {companyData?.data?.length > 0 && companyData?.data?.map((company, index) => (
                <Link to={company?.link} className="col-lg-4 col-md-6 col-12 contact-address ">
                  <div key={index} className="">
                    <div className="">
                      <motion.div
                        initial={{
                          opacity: 0,
                          y: 75,
                        }}
                        whileInView={{
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 1,
                          },
                        }}
                        viewport={{ once: false }}
                      >
                        <h5 className="company-name mb-4">{company.name}</h5>
                        <div className="row">
                          <div className="col-lg-1 col-1">
                            <i className="fas fa-map-marker-alt contact-icon"></i>
                          </div>
                          <div className="col-lg-10 col-10 text-dark">
                            <div className="address-contact">{company.address}</div>
                          </div>
                        </div>
                      </motion.div>
                    </div>

                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default Contact;
