import React from "react";
import "./Loader.css";

import eg from "../Assets/images/logo/eg.png"

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={eg} alt="logo" className="loader" />
    </div>
  );
};

export default Loader;
